import classNames from 'classnames/bind';
import Header from 'components/Header';
import About from 'pages/About';
import Contact from 'pages/Contact';
import Search from 'pages/Search';
import Test from 'pages/Test';
import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import styles from './App.scss';
import Footer from './components/Footer';

const cx = classNames.bind(styles);

function App() {
    return (
        <Router>
            <div className={cx('container')}>
                <Header />
                <main>
                    <Switch>
                        <Route exact path="/">
                            <Search />
                        </Route>
                        <Route path="/about">
                            <About />
                        </Route>
                        <Route path="/contact">
                            <Contact />
                        </Route>
                        <Route path="/test">
                            <Test />
                        </Route>
                    </Switch>
                </main>
                <Footer></Footer>
            </div>
        </Router>
    );
}

export default App;
