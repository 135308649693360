import useMediaQuery from '@material-ui/core/useMediaQuery';
import classNames from 'classnames/bind';
import {ReactComponent as MenuIcon} from 'images/header/menu.svg';
import {ReactComponent as SearchIcon} from 'images/header/search_green.svg';
import logo from 'images/logo.png';
import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import styles from './Header.module.scss';

const cx = classNames.bind(styles);

const Header = () => {
    const isMobile = useMediaQuery('(max-width:1050px)');
    const [showOverlay, setShowOverlay] = useState(false);

    if (isMobile) {
        return (
            <>
                <nav className={cx('mobile')}>
                    <NavLink
                        exact={true}
                        activeClassName={cx('active')}
                        to="/"
                        className={cx('search')}
                        onClick={() => setShowOverlay(false)}
                    >
                        <SearchIcon />
                    </NavLink>
                    <div className={cx('logo')}>
                        <a href='/'><img src={logo} alt="Logo" /></a>
                    </div>
                    <div
                        className={cx('menu')}
                        onClick={() => setShowOverlay(!showOverlay)}
                    >
                        <MenuIcon />
                    </div>
                </nav>
                <div className={cx('overlay', {visible: showOverlay})}>
                    <ul>
                        <li>
                            <NavLink
                                exact={true}
                                activeClassName={cx('active')}
                                to="/about"
                                onClick={() => setShowOverlay(false)}
                            >
                                About
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                exact={true}
                                activeClassName={cx('active')}
                                to="/contact"
                                onClick={() => setShowOverlay(false)}
                            >
                                Contact
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </>
        );
    }

    return (
        <nav>
            <div className={cx('logo')}>
                <a href='/'><img src={logo} alt="Logo" /></a>
            </div>
            <ul>
                <li>
                    <NavLink exact={true} activeClassName={cx('active')} to="/">
                        Search Med-Vue
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink
                        exact={true}
                        activeClassName={cx('active')}
                        to="/test">
                        Test
                    </NavLink>
                </li> */}
                <li>
                    <NavLink
                        exact={true}
                        activeClassName={cx('active')}
                        to="/about"
                    >
                        About
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        exact={true}
                        activeClassName={cx('active')}
                        to="/contact"
                    >
                        Contact
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default Header;
