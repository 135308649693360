import React, {useState} from 'react';
import classNames from 'classnames/bind';
import styles from './About.module.scss';

const cx = classNames.bind(styles);

const Test = () => {
    const [faded, setFaded] = useState(false);
    const fadeout = (e) => setFaded(!faded);

    return <div className={cx('test', 'center')}>
        <h1>Test</h1>
        <div className={cx(faded ? 'faded' : 'not-faded')}>
            <img width="640" src=".." onClick={(e) => fadeout(e)} />
            <p>
                Test page
            </p>
        </div>
    </div>;
};

export default Test;
